<template>
  <div>

    <!-- Media -->
    <b-media class="mb-2">
      <!-- <template #aside>
        <b-avatar
          ref="previewEl"
          :src="userData.avatar"
          :text="avatarText(userData.fullName)"
          :variant="`light-${resolveUserRoleVariant(userData.role)}`"
          size="90px"
          rounded
        />
      </template> -->
      <h2 class="mb-1">
        {{ userData.lastname }} {{ userData.firstname }}
      </h2>
      <!-- <div class="d-flex flex-wrap">
        <b-button
          variant="primary"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="inputImageRenderer"
          >
          <span class="d-none d-sm-inline">Update</span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
        <b-button
          variant="outline-secondary"
          class="ml-1"
        >
          <span class="d-none d-sm-inline">Remove</span>
          <feather-icon
            icon="TrashIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
      </div> -->
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form
      @submit.prevent="onSubmitUpdateUser"
    >
      <b-row>

        <!-- Field: Last Name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Last Name"
            label-for="lastname"
          >
            <b-form-input
              id="lastname"
              v-model="userData.lastname"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Last Name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="First Name"
            label-for="first-name"
          >
            <b-form-input
              id="first-name"
              v-model="userData.firstname"
            />
          </b-form-group>
        </b-col>

        <!-- Field: First Name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Username"
            label-for="username"
          >
            <b-form-input
              id="username"
              v-model="userData.username"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="userData.contactInfo.email"
              type="email"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Phone"
            label-for="phone"
          >
            <b-form-input
              id="phone"
              v-model="userData.contactInfo.phone"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Role -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="User Role"
            label-for="user-role"
          >
            <v-select
              v-model="userData.role"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="roleOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="user-role"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col
          v-if="userData.role === 'CLIENT'"
          cols="12"
          md="4"
        >
          <b-form-group
            label="Business"
            label-for="business"
          >
            <b-form-input
              id="business"
              v-model="userData.business"
            />
          </b-form-group>
        </b-col>

      </b-row>

      <b-row
        class="pt-1"
      >
        <b-col
          cols="12"
          md="4"
        >
          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmitUpdateUser"
          >
            Save Changes
          </b-button>
        </b-col>
      </b-row>
    </b-form>

    <!-- PERMISSION TABLE -->
    <b-card
      no-body
      class="border mt-3"
    >
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon
            icon="LockIcon"
            size="18"
          />
          <span class="align-middle ml-50">Assignation</span>
        </b-card-title>
      </b-card-header>
      <b-card-body>
        <b-form>
          <b-row>
            <!-- Field: Role -->
            <b-col
              cols="11"
              md="6"
              class="m-2"
            >
              <b-form-group
                label=""
                label-for="user-role"
              >
                <v-select
                  v-model="clientListAssigned"
                  :options="clientList"
                  multiple
                  input-id="user-role"
                  class="select-size-lg"
                  label="fullname"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="4"
              class="mb-2 mx-2"
            >
              <!-- Action Buttons -->
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="onSubmitAssignUser"
              >
                Assign
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card-body>
    </b-card>

  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  // BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BCardBody,
  // BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  // BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref } from '@vue/composition-api'
import useUsersList from '../users-list/useUsersList'
import store from '@/store'

export default {
  components: {
    BButton,
    BMedia,
    // BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCardBody,
    // BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    // BFormCheckbox,
    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    }
  },
  setup(props) {
    const { resolveUserRoleVariant } = useUsersList()
    const toast = useToast()

    const roleOptions = [
      { label: 'Admin', value: 'ADMIN' },
      { label: 'Supervisor', value: 'SUPERVISOR' },
      { label: 'Client', value: 'CLIENT' },
      { label: 'Agent', value: 'AGENT' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const permissionsData = [
      {
        module: 'Admin',
        read: true,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'Staff',
        read: false,
        write: true,
        create: false,
        delete: false,
      },
      {
        module: 'Author',
        read: true,
        write: false,
        create: true,
        delete: false,
      },
      {
        module: 'Contributor',
        read: false,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'User',
        read: false,
        write: false,
        create: false,
        delete: true,
      },
    ]

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const clientList = ref([])
    const clientListAssigned = ref([])
    const clientListAssignedStorage = ref([])

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.avatar = base64
    })

    const onSubmitUpdateUser = () => {
      store.dispatch('app-user/updateUser', props.userData).then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Profile Updated Succesfully',
            text: 'Welcome',
            icon: 'LockIcon',
            variant: 'success',
          },
        })
      })
    }
    /* eslint-disable no-param-reassign */
    const formatName = assignedUsers => {
      if (!assignedUsers) return []
      assignedUsers.forEach(assignedUser => {
        assignedUser.fullname = `${assignedUser.lastname} ${assignedUser.firstname}`
      })
      return assignedUsers
    }
    /* eslint-enable no-param-reassign */

    const excludeObjectsByRole = (objects, excludedRoles) => objects.filter(obj => !excludedRoles.includes(obj.role))
    clientListAssigned.value = formatName(props.userData.assignedUsers)
    clientListAssignedStorage.value = formatName(props.userData.assignedUsers)

    let rolesToExclude = []
    switch (props.userData.role) {
      case 'SUPERVISOR':
        rolesToExclude = ['SUPERVISOR']
        break
      case 'CLIENT':
        rolesToExclude = ['CLIENT']
        break
      case 'AGENT':
        rolesToExclude = ['SUPERVISOR', 'AGENT']
        break
      default:
        break
    }

    store
      .dispatch('app-user/fetchUsers', {})
      .then(response => {
        clientList.value = formatName(excludeObjectsByRole(response.data, rolesToExclude))
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching data',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

    const findMissingObjects = (mainArr, otherArr) => otherArr.filter(obj => !mainArr.some(mainObj => mainObj.id === obj.id))

    // Find objects in array2 but not in array1

    const onSubmitAssignUser = () => {
      const { role } = props.userData
      // const roleUsertoEdit = role
      const missingObjectsInMain = findMissingObjects(clientListAssignedStorage.value, clientListAssigned.value)
      missingObjectsInMain.forEach(element => {
        // let createKanban = false
        // const kanban = {
        //   title: 'Kanban',
        //   description: '',
        //   client: '',
        //   agent: '',
        // }
        // if (roleUsertoEdit === 'CLIENT' && element.role === 'AGENT') {
        //   kanban.client = props.userData.id
        //   kanban.agent = element.id
        //   createKanban = true
        // } else if (roleUsertoEdit === 'AGENT' && element.role === 'CLIENT') {
        //   kanban.agent = props.userData.id
        //   kanban.client = element.id
        //   createKanban = true
        // } else if (roleUsertoEdit === 'SUPERVISOR' && element.role === 'CLIENT') {
        //   kanban.agent = props.userData.id
        //   kanban.client = element.id
        //   createKanban = true
        // } else if (roleUsertoEdit === 'CLIENT' && element.role === 'SUPERVISOR') {
        //   kanban.client = props.userData.id
        //   kanban.agent = element.id
        //   createKanban = true
        // }
        // console.log(props.userData, element)
        // console.log(roleUsertoEdit, element.role, kanban, createKanban)

        let assignation = {
          owner: props.userData.id,
          toAssign: element.id,
        }
        if (role === 'CLIENT') {
          assignation = {
            owner: element.id,
            toAssign: props.userData.id,
          }
        }
        // console.log(assignation)
        // if (createKanban) store.dispatch('app-user/createKanban', kanban).then(() => {})
        store.dispatch('app-user/assignUser', assignation).then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Assigned Successfully',
              icon: 'LockIcon',
              variant: 'success',
            },
          })
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error assigning user',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
      })
    }

    return {
      clientList,
      clientListAssigned,

      resolveUserRoleVariant,
      avatarText,
      roleOptions,
      statusOptions,
      permissionsData,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
      onSubmitUpdateUser,
      onSubmitAssignUser,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
